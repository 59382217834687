<template>
    <div class="home-search-component">
        <div class="row main-row">
            <div class="col-md-5 pr-0" :class="onlysearch ? 'only-search':''">
                <div class="home-search-box">

                    <h1 v-if="checkIsMobile && !onlysearch" >{{ __("A mais completa imobiliária de Curitiba no Paraná e Santa Catarina") }}</h1>
                    <h2 v-if="!onlysearch">{{ __("Encontre os melhores imóveis para comprar ou alugar") }}</h2>

                    <form action="">

                        <div class="business-button">
                            <label 
                                class="business_button" 
                                :class="filters.business == 'Vendas' ? 'checked':''" 
                                for="business_vendas"
                                @click="()=>{filters.business_subfilter = 'Residencial'; clearFilter(true)}"
                            >
                                <span>{{ __("COMPRAR") }}</span>
                                <input name="business" v-model="filters.business" type="radio" value="Vendas" id="business_vendas"/>
                            </label>

                            <label 
                                class="business_button" 
                                :class="filters.business == 'Locacao' ? 'checked':''" 
                                for="business_locacao"
                                @click="()=>{filters.business_subfilter = 'Mensal'; clearFilter(true)}"
                            >
                                <span>{{ __("ALUGAR") }}</span>
                                <input name="business" v-model="filters.business" type="radio" value="Locacao" id="business_locacao"/>
                            </label>
                        </div>

                        <div v-if="filters.business" class="business-sub-filters" :class="`business-${filters.business}`">
                            <div 
                                v-for="sub,subkey in _business_subfilters" 
                                class="button"
                                :class="filters.business_subfilter == sub.label ? 'checked' : ''"
                                v-bind:key="`sub_${sub.label}_${subkey}`"
                                @click="()=>{filters.business_subfilter = sub.label; clearFilter(true)}"
                            >
                                {{__(sub.label)}}
                            </div>
                        </div>

                        <div class="main-filters input-filters" :class="!show_other_types ? 'no-border':''">

                            <div v-if="filter_by_ref" class="row">
                                <div class="col-md-12">
                                    <div class="input">
                                        <label class="use_icon reference" for="">
                                            <span class="text">Código</span>
                                        </label>
                                        
                                        <VSelectInfinite
                                            :options="filters_options.references"
                                            :placeholder="'Procurar por código'"
                                            v-model="filters.reference"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div v-show="!use_advanced_filters && !filter_by_ref" class="row">
                                <div class="col-md-12">
                                    <div class="input search_by">
                                        <label class="use_icon gps" for="">
                                            <span class="text">{{ __("Buscar por:") }}</span>
                                        </label>
                                        <VSelectInfinite
                                            ref="vselectsearchby"
                                            :options="filters_options.open_search"
                                            :placeholder="__('Bairro, cidade, endereço ou referência')"
                                            v-model="filters.open_search"
                                            v-on:input="handleOpenSearch"
                                            :min_search="true"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div v-show="use_advanced_filters && !filter_by_ref" class="row">
                                <div class="col-md-12">
                                    <div class="input">
                                        <label class="use_icon pin" for="">
                                            <span class="text">{{__("Cidade")}}</span>
                                        </label>
                                         <VSelectInfinite
                                            ref="vselectcity"
                                            :options="citiesOptions"
                                            :placeholder="__('Procurar por cidade')"
                                            v-model="filters.city"
                                            v-on:input="() => {filters.district = []}"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div v-show="use_advanced_filters && !filter_by_ref" class="row">
                                <div class="col-md-12">
                                    <div class="input">
                                        <label class="use_icon target" for="">
                                            <span class="text">{{ __("Bairro") }}</span>
                                        </label>


                                        <VSelectMultipleVue
                                            :placeholder="__('Procurar por bairro')"
                                            v-model="filters.district"
                                            :options="districtOptions"
                                            v-on:input="changePropertyTypeCombo()"
                                            :empty_label="filters.city ? 'Nenhuma opção encontrada.': 'Nenhuma cidade selecionada.'"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-2" v-show="!use_advanced_filters && visual_types && visual_types.length > 0">
                                <div class="col-12">
                                    <div class="business-visual-types">
                                        <div 
                                            v-for="type,idx in visual_types" 
                                            v-bind:key="`visual-type-${idx}`" 
                                            class="visual-type" 
                                            :class="getVisualTypeClass(type)"
                                            v-on:click="()=>{handlePropertyTypeButtons(type.id);}"
                                        >
                                            <img :width="type.size.split('x')[0]" :height="type.size.split('x')[1]" :alt="type.label" :src="type.img"/>
                                            <span>{{ __(type.label) }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-show="!show_other_types && filters.business_subfilter != 'Temporário'" class="row trigger-property-types">
                                <div class="col-md-12">
                                    <div @click="()=>{show_other_types = !show_other_types}" class="others-properties-types">
                                        {{ __("Outros tipos de imóveis") }}
                                    </div>
                                </div>
                            </div>

                            <div v-show="!filter_by_ref && show_other_types" class="row property-types-wrapper">
                                <div class="col-md-12">
                                    <div class="input">
                                        <label class="use_icon house" for="">
                                            <span class="text">{{__("Tipo de imóveis:")}}</span>
                                        </label>

                                        <VSelectMultipleVue
                                            :placeholder="__('Selecione o tipo de imóvel')"
                                            v-model="filters.property_type"
                                            :options="property_type_purpose"
                                            v-on:input="changePropertyTypeCombo()"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div v-show="use_advanced_filters && !filter_by_ref" class="row">
                                <div class="col-6 float-field">
                                    <div class="input">
                                        <label class="use_icon money" for="">
                                            <span class="text">Valor Min</span>
                                        </label>
                                        <input v-money="moneyMask" v-model="filters.price_min" type="text" :placeholder="__('Valor Mínimo')">
                                    </div>
                                </div>

                                <div class="col-6 float-field">
                                    <div class="input">
                                        <label class="use_icon money" for="">
                                            <span class="text">Valor Max</span>
                                        </label>
                                        <input v-money="moneyMask" v-model="filters.price_max" type="text" :placeholder="__('Valor Máximo')">
                                    </div>
                                </div>
                            </div>


                            <div v-show="use_advanced_filters && !filter_by_ref" class="row">
                                <div class="col-6 float-field">
                                    <div class="input">
                                        <label class="use_icon bed" for="">
                                            <span class="text">{{__('Quartos')}}</span>
                                        </label>

                                        <VSelectMultipleVue
                                            :placeholder="__('Nº de quartos')"
                                            v-model="filters.bedrooms"
                                            :options="numeric_options(4, __('quartos'))"
                                        />
                                    </div>
                                </div>

                                
                                <div class="col-6 float-field">
                                    <div class="input">
                                        <label class="use_icon car" for="">
                                            <span class="text">{{__('Vagas')}}</span>
                                        </label>

                                        <VSelectMultipleVue
                                            :placeholder="__('Nº de vagas')"
                                            v-model="filters.garage"
                                            :options="numeric_options(4, __('vagas'), '', true)"
                                        />

                                    </div>
                                </div>
                            </div>

                        </div>

                        <div v-if="lang=='ptBR'" class="row">
                            <div class="col-6">
                                <div @click="()=>{use_advanced_filters = !use_advanced_filters; loadExistingFilters(true); show_other_types=true;}" class="button-change-form use_icon filter" :class="use_advanced_filters ? 'close-filter':''">
                                    <span class="text" v-html="use_advanced_filters? __('Fechar filtros avançados'): __('Filtros Avançados')" ></span>
                                </div>
                            </div>

                            <div  class="col-6">
                                <div @click="clearFilter()" class="button-change-form use_icon clear_filters">
                                    <span class="text" v-html="__('Limpar filtros')" ></span>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div  @click="search" class="filter-button use_icon search">
                                    <span>{{ __("ENCONTRAR IMÓVEIS") }}</span>
                                </div>
                            </div>
                        </div>
                        
                    </form>
                </div>
            </div>
            <div v-if="!checkIsMobile && !onlysearch" class="col-md-7 pl-0">
                <h1>{{ __("A mais completa imobiliária de Curitiba no Paraná e Santa Catarina") }}</h1>

                <a v-if="search_banners.link" :href="search_banners.link" target="_blank">
                    <img :alt="search_banners.alt" width="1" height="1" class="home-banner" :src="search_banners.image"/>
                </a>

                <img v-if="!search_banners.link" :alt="search_banners.alt" width="1" height="1" class="home-banner" :src="search_banners.image"/>

            </div>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import "@/assets/scss/customB/principal/components/_home_search.scss"
import jQuery from "jquery";
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import {VMoney} from 'v-money'
import VSelectInfinite from "../VSelectInfinite.vue"
import VSelectMultipleVue from '../VSelectMultiple.vue';

import PropertiesService from "../../../../services/PropertiesService";


export default {
  directives: {money: VMoney},
  components: {
    vSelect,
    VSelectInfinite,
    VSelectMultipleVue
  },
  props: {
    onlysearch: {
      type: Boolean,
      default() {
        return false
      }
    },
    openAdvanced: {
        type: Boolean,
        default() {
            return true
        }
    },
    clearFilterOnOpen: {
        type: Boolean,
        default() {
            return false
        }
    }
  },
  data() {
    return {
        filters: {
            business: "Vendas", //ok
            business_subfilter: "Residencial", //ok
            reference: null, //ok
            city: null, //ok
            district: [], //ok
            property_type: [], //ok
            property_type_combo: [], // ok
            bedrooms: [], //ok
            garage: [], //ok
            price_max: null, //ok
            price_min: null, //ok
            address: null, //ok
            address_number: null,
            open_search: '',//ok
            in_condominium: false // ok
        },
        filters_options: {
            sub_filters: {
                "Vendas":[
                        {
                            "id":"",
                            "label": "Residencial",
                            "types": [
                                {"id":"Apartamento", "label":"Apartamento", "img": require("@/assets/img/home/apartamento.webp"), "size": "63x63"},
                                {"id":"Casa|Sobrado", "label":"Casa e sobrado", "img": require("@/assets/img/home/casa-sobrado.webp"), "size": "53x53"},
                                {"id":"Studio|Kitnet", "label":"Studio e kitnet", "img": require("@/assets/img/home/studio.webp"), "size": "63x63"},
                                {"id":"Terreno", "label":"Terreno", "img": require("@/assets/img/home/terreno.webp"), "size": "51x51"}
                            ]
                        },
                        {
                            "id":"",
                            "label": "Comercial",
                            "types": [
                                {"id":"Sala", "label":"Sala", "img": require("@/assets/img/home/sala-predio.webp"), "size": "62x63"},
                                {"id":"Loja", "label":"Loja", "img": require("@/assets/img/home/loja.webp"), "size": "56x56"},
                                {"id":"Casa|Sobrado", "label":"Casa comercial", "img": require("@/assets/img/home/casa-comercial.webp"), "size":"61x60"},
                                {"id":"Barracão", "label":"Barracão", "img": require("@/assets/img/home/barracao.webp"), "size": "56x56"}
                            ]
                        },
                        {
                            "id":"",
                            "label": "Lançamentos",
                            "types": [
                                {"id":"Apartamento", "label":"Apartamento", "img": require("@/assets/img/home/apartamento.webp"), "size": "63x63"},
                                {"id":"Casa|Sobrado", "label":"Casa e sobrado", "img": require("@/assets/img/home/casa-sobrado.webp"), "size": "53x53"},
                                {"id":"Studio", "label":"Studio", "img": require("@/assets/img/home/studio.webp"), "size": "63x63"},
                                {"id":"Comercial", "label":"Comercial", "img": require("@/assets/img/home/loja.webp"), "size": "56x56"}
                            ]
                        },
                        {
                            "id":"",
                            "label": "Triple A",
                            "types": [
                                {"id":"Apartamento", "label":"Apartamento", "img": require("@/assets/img/home/apartamento.webp"), "size": "63x63"},
                                {"id":"Casa|Sobrado", "label":"Casa e sobrado", "img": require("@/assets/img/home/casa-sobrado.webp"), "size": "53x53"},
                                {"id":"Studio", "label":"Studio", "img": require("@/assets/img/home/studio.webp"), "size": "63x63"},
                                {"id":"Comercial", "label":"Comercial", "img": require("@/assets/img/home/loja.webp"), "size": "56x56"}
                            ]
                        }
                ],
                "Locacao":[
                    {
                        "id":"",
                        "label": "Mensal",
                        "types": [
                            {"id":"Apartamento", "label":"Apartamento", "img": require("@/assets/img/home/apartamento.webp"), "size": "63x63"},
                            {"id":"Casa|Sobrado", "label":"Casa e sobrado", "img": require("@/assets/img/home/casa-sobrado.webp"), "size": "53x53"},
                            {"id":"Studio|Kitnet", "label":"Studio e kitnet", "img": require("@/assets/img/home/studio.webp"), "size": "63x63"},
                            {"id":"Terreno", "label":"Terreno", "img": require("@/assets/img/home/terreno.webp"), "size": "51x51"}
                        ]
                    },
                    {
                        "id":"",
                        "label": "Comercial",
                        "types": [
                            {"id":"Sala", "label":"Sala", "img": require("@/assets/img/home/sala-predio.webp"), "size": "62x63"},
                            {"id":"Loja", "label":"Loja", "img": require("@/assets/img/home/loja.webp"), "size": "56x56"},
                            {"id":"Casa|Sobrado", "label":"Casa comercial", "img": require("@/assets/img/home/casa-comercial.webp"), "size":"61x60"},
                            {"id":"Barracão", "label":"Barracão", "img": require("@/assets/img/home/barracao.webp"), "size": "56x56"}
                        ]
                    },
                    {
                        "id":"",
                        "label": "Temporário",
                        "types": [
                            {"id":"Apartamento", "label":"Apartamento", "img": require("@/assets/img/home/apartamento.webp"), "size": "63x63"},
                            {"id":"Studio", "label":"Studio", "img": require("@/assets/img/home/studio.webp"), "size": "63x63"},
                            {"id":"Casa|Sobrado", "label":"Casa e sobrado", "img": require("@/assets/img/home/casa-sobrado.webp"), "size": "53x53"},
                            {"id":"Outros", "label":"Outros imóveis", "img": require("@/assets/img/home/terreno.webp"), "size": "51x51"}
                        ]
                    },
                ]
            },
            cities: [],
            types: [],
            references: [],
            adresses: [],
            open_search: []
        },
        show_other_types: false,
        use_advanced_filters: false,
        filter_by_ref: false,
        moneyMask: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          suffix: '',
          precision: 2,
          masked: false /* doesn't work with directive */
        },
        old_site_url: "https://www.apolar.com.br",
        blacknovember: false
    };
  },
  computed: {
      
      search_banners() {

        if ( this.blacknovember && this.lang != 'es' ) {
            return {
                "image": require("@/assets/img/home/banners/blacknovember.webp"),
                "alt": "Black November - Imóveis com Descontos Especiais",
                "link": "https://www.apolar.com.br/feirao-apolar"
            }
        }


        switch (this.filters.business_subfilter) {
            case "Comercial":
                return {
                    "image": require("@/assets/img/home/banners/comercial.webp"),
                    "alt": "Mulher jovem e feliz no trabalho em um imóvel comercial"
                }
                break;
            
            case "Lançamentos":
            case "Temporário":
                return {
                    "image": require("@/assets/img/home/banners/lancamento_temporario.webp"),
                    "alt": "Pai jovem e feliz brincando com seu filho no chão de seu apartamento novo"
                }
                break;

            case "Residencial":
            case "Mensal":
                if (this.lang == 'es') {
                    return {
                        "image": require("@/assets/img/home/banners/residencial_mensal_py.webp"),
                        "alt": "Familia feliz en su nuevo inmueble o casa en Asunción"
                    }
                }
                return {
                    "image": require("@/assets/img/home/banners/residencial_mensal.webp"),
                    "alt": "Casal jovem brincando com o cachorro durante o café da manhã na sala de estar de seu novo imóvel"
                }
                break;

            case "Triple A":
                return {
                    "image": require("@/assets/img/home/banners/triplea.webp"),
                    "alt": "Pai feliz sentado em seu sofá confortável, brincando com suas filhas em uma sala de estar moderna e sofisticada."
                }
                break;
        
            default:
                return {
                    "image": require("@/assets/img/home/banners/home-search-bg.webp"),
                    "alt": "Família feliz com cachorro em nova casa"
                }
                break;
        }
      },
      citiesOptions() {
          return this.filters_options.cities.map(item=>{return item.city})
      },
      districtOptions() {
        //this.filters.district = []
        if (this.filters.city == null || this.filters.city == ""){
            return []
        }

        let districts = this.filters_options.cities.filter(item => item.city == this.filters.city)[0].districts.sort(function (a, b) {
            return a.localeCompare(b);
        })

        //to remove register duplicated and wrong
        districts.forEach((district, key) => {
            let district_sanitized = district.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '')

            let exists = districts.indexOf(district_sanitized)
            if ( exists != -1 && district_sanitized != district  ) {
                delete districts[exists]
            }
        })
        
        return districts.sort(function (a, b) {
            return a.localeCompare(b);
        });

      },

      visual_types() {
        if ( this.filters.business && this.filters.business_subfilter ) {
            let sub_filters = this.filters_options__sub_filters[this.filters.business]
            let sub_filter = sub_filters.filter(sub => sub.label == this.filters.business_subfilter)
            return sub_filter.length > 0 ? sub_filter[0]['types'] : null
        }
        return null
      }, 

      property_type_purpose() {
        let comercial_types = ['Barracão', 'Conjunto', 'Hotel', 'Loja', 'Ponto', 'Prédio', 'Sala', 'Sobreloja', 'Área', 'Casa', 'Terreno']
        if (this.filters.business_subfilter == "Comercial") {
            return comercial_types.sort(function (a, b) {
                        return a.localeCompare(b);
                })
        }

        let residencial_types = this.filters_options.types.filter(type => comercial_types.indexOf(type) == -1)
        

        if ( this.lang != 'es' ) {
            residencial_types = residencial_types.concat('Área', 'Casa', 'Terreno')
        }

        if ( this.lang == 'es' ) {
            residencial_types = residencial_types.filter(type => type != 'Flat')
        }

        return residencial_types.sort(function (a, b) {
                    return a.localeCompare(b);
            })
      },

      _business_subfilters() {
        let subfilters = this.filters_options__sub_filters[this.filters.business]

        if ( this.lang == 'es' ) {
            var ignore_subfilters = []
            if ( this.filters.business == 'Vendas' ) {
                ignore_subfilters = ['Lançamentos', 'Triple A']
            } else {
                ignore_subfilters = ['Temporário']
            }
            return subfilters.filter(sf => ignore_subfilters.indexOf(sf.label) == -1)
        }   

        return subfilters
      },

      filters_options__sub_filters() {

            let sub_filters = this.filters_options.sub_filters

            if ( this.lang == "es" ) {
                sub_filters['Vendas'][0] = {
                        "id":"",
                        "label": "Residencial",
                        "types": [
                            {"id":"Apartamento", "label":"Apartamento", "img": require("@/assets/img/home/apartamento.webp"), "size": "63x63"},
                            {"id":"Casa|Sobrado", "label":"Casa e sobrado", "img": require("@/assets/img/home/casa-sobrado.webp"), "size": "53x53"},
                            {"id":"Studio|Kitnet", "label":"Studio e kitnet", "img": require("@/assets/img/home/studio.webp"), "size": "63x63"},
                            {"id":"Terreno", "label":"Terreno", "img": require("@/assets/img/home/terreno.webp"), "size": "51x51"}
                        ]
                }
            }

            return sub_filters
        
    }
  },
  methods: {

    getVisualTypeClass(type) {
        let _class = `visual-type-${this.slugify(type.id)} `
        if ( type.id == "Outros" ) {
            _class += `trigger-property-types `
        }
        _class +=  `${this.filters.property_type_combo.indexOf(type.id) != -1 ? 'active' : ''}`
        return _class
    },

    clearReferenceFilter() {
        this.filters.reference = null
    },

    numeric_options(range=10, label, sufix, zero_option=false) {
        let options = [];

        if (zero_option) {
            options.push({
                "label": `0`,
                "id": 0
            })
        }

        [...Array(range).keys()].forEach((num)=>{
            let opt_num = num + 1
            let _sufix = sufix ? sufix : null
            if ( !_sufix ) {
                _sufix = opt_num >=4 ? '+':''
            }
            options.push({
                "label": `${opt_num}${_sufix}`,
                "id": opt_num
            })
        })
        return options
    },

    checkPropertyTypeHidden() {

        if ( this.use_advanced_filters ) {
            this.show_other_types = true
        } else {
            this.show_other_types = false
        }

    },

    handleOpenSearch() {
        
        let open_search = this.filters.open_search
        let check_is_reference = parseInt(open_search)

        console.log("open_search: ", open_search)

        //check se é busca por numero de referencia
        if ( !isNaN(check_is_reference) ) {
            this.filters.reference = check_is_reference
        } else {

            this.filters.reference = null
            let open_search_parts = open_search.split(',')
            
            switch (open_search_parts.length) {
                case 1:
                    Vue.set(this.filters, 'city', open_search_parts[0].trim())
                    Vue.set(this.filters, 'district', [])
                    Vue.set(this.filters, 'address', null)
                    Vue.set(this.filters, 'address_number', null)
                    break;

                case 2:
                    Vue.set(this.filters, 'city', open_search_parts[1].trim())
                    Vue.set(this.filters, 'district', [open_search_parts[0].trim()])
                    Vue.set(this.filters, 'address', null)
                    Vue.set(this.filters, 'address_number', null)
                    break;

                case 3:
                    Vue.set(this.filters, 'city', open_search_parts[2].trim())
                    //Vue.set(this.filters, 'district', [open_search_parts[1].trim()])
                    Vue.set(this.filters, 'address', open_search_parts[0].trim())
                    Vue.set(this.filters, 'address_number', null)
                    break;

                case 4:
                    Vue.set(this.filters, 'city', open_search_parts[3].trim())
                    //Vue.set(this.filters, 'district', [open_search_parts[1].trim()])
                    Vue.set(this.filters, 'address_number', open_search_parts[1].trim())
                    Vue.set(this.filters, 'address', open_search_parts[0].trim())
                    break;

                default:
                    break;
            }

        }

        this.loadExistingFilters(true)
        
    },


    //on change type by button, reflect on select field type
    handlePropertyTypeButtons(type) {
        
        if (type == "Outros") {
            this.show_other_types = !this.show_other_types
            return false
        }

        let type_index = this.filters.property_type_combo.indexOf(type)

        if ( type_index == -1 ) {
            this.filters.property_type_combo.push(type)

            this.filters.property_type_combo.forEach((propType)=>{
                let types = propType.split("|")
                types.forEach((type)=>{
                    if ( this.filters.property_type.indexOf(type) == -1 && type != 'Condominio' ) {
                        this.filters.property_type.push(type)
                    }
                })
            })

        } else {
            this.filters.property_type_combo.splice(type_index, 1)
            type.split("|").forEach((sub_type)=>{
                let sub_type_idx =  this.filters.property_type.indexOf(sub_type)
                if ( sub_type_idx != -1 ) {
                    this.filters.property_type.splice(sub_type_idx, 1)
                }
            })
        }

        this.filters.in_condominium = false
        if ( this.filters.property_type_combo.indexOf("Condominio") != -1 ) {
            this.filters.in_condominium = true
        }

    },

    //on change type by select, reflect on buttons type
    changePropertyTypeCombo() {

        let possible_types = []
        let possible_types_vendas = this.filters_options__sub_filters['Vendas'].map(item => item['types'].map(sub =>sub.id))
        let possible_types_locacao = this.filters_options__sub_filters['Locacao'].map(item => item['types'].map(sub =>sub.id))
        possible_types_vendas.forEach((list_types)=>{possible_types = possible_types.concat(list_types)})
        possible_types_locacao.forEach((list_types)=>{possible_types = possible_types.concat(list_types)})
        possible_types = possible_types.filter(type => type != 'Casa' && type != 'Studio')
        possible_types = [...new Set(possible_types)]
                                

        this.filters.property_type.forEach((type)=>{
            let possible_type_idx = possible_types.findIndex(p_type => p_type.indexOf(type) != -1)
            let possible_type = possible_types[possible_type_idx]

            if ( possible_type != -1 && this.filters.property_type_combo.indexOf(possible_type) == -1  ) {
                this.filters.property_type_combo.push(possible_type)
            }
        })

        this.filters.property_type_combo.forEach((propType, idx)=>{
            if(typeof propType != "undefined") {
                let type_changed = this.filters.property_type.findIndex(type => propType.indexOf(type) != -1)
                console.log(propType, idx, type_changed)
                if (type_changed == -1) {
                    this.filters.property_type_combo.splice(idx, 1)
                }
            }
        })
        
    },


    search() {

        //busca preparada para enviar para site atual em wordpress
        let filters = {}
        filters.filters = {}
        
        if (this.filters.reference) {
            filters.filters.referencia = this.filters.reference
        } else {

            if (this.filters.business) {
                filters.filters.finalidade = this.filters.business
            }

            if (this.filters.city) {
                filters.filters.cidade = this.filters.city ? this.filters.city.trim() : null
            }

            if (this.filters.district) {
                filters.filters.bairro = this.filters.district
            }
            
            if ( this.filters.property_type ) {
                filters.filters.tipo = this.filters.property_type
            }
            
            if ( this.filters.bedrooms ) {
                filters.filters.quartos = this.filters.bedrooms.id
            }

            if ( this.filters.garage ) {
                filters.filters.vagas = this.filters.garage.id
            }

            if ( this.filters.address ) {
                filters.filters.endereco = this.filters.address ? this.filters.address.trim() : null
            }
            
            filters.filters.preco_de = this.filters.price_min ? parseFloat(this.filters.price_min.replace('R$','').replace('.','').replace(',','.')) : 0.00
            filters.filters.preco_ate = this.filters.price_max ? parseFloat(this.filters.price_max.replace('R$','').replace('.','').replace(',','.')) : 0.00
        }


        let filter_type = this.filters.property_type && this.filters.property_type.length > 0 ? this.slugify(this.filters.property_type[0]) : null
        let purpose = this.slugify(this.filters.business_subfilter)

        if (purpose == "comercial" && filter_type == "sala") {
            filter_type = "sala-comercial"
        }
        // console.log("filter_type: ", filter_type)
        // console.log("purpose: ", purpose)
        // return false

        if ( this.filters.property_type_combo.length > 0 && !filter_type ) {
            let type_combo = this.filters.property_type_combo[0].split("|")
            filter_type = this.slugify(type_combo[0])
        }

        let bedrooms_label = 'quartos'
        if (this.filters.bedrooms && (this.filters.bedrooms.length == 1 && this.filters.bedrooms[0] <=1 )) {
            bedrooms_label = 'quarto'
        }

        let route_params = {
            transaction: this.filters.business == "Vendas" ? 'venda' : 'alugar',
            type: filter_type,
            city: this.filters.city ? this.slugify(this.filters.city) :  null,
            district: this.filters.district && this.filters.district.length > 0 ? this.slugify(this.filters.district[0]) : null,
            address: this.filters.address ? this.slugify(this.filters.address) :  null,
            bedrooms: this.filters.bedrooms && this.filters.bedrooms.length > 0 ? `${this.filters.bedrooms.join('-')}-${bedrooms_label}` :  null
        }

        let query = {}
        query[purpose]=null;

        let ignore_fields = ['business', 'business_subfilter', 'property_type_combo', 'open_search', 'city', 'bedrooms']
        let handle_float_values = ['price_min', 'price_max']

        if ( this.filters.property_type.length == 1 ) {
            ignore_fields.push('property_type')
        }

        if ( this.filters.district.length == 1 ) {
            ignore_fields.push('district')
        }

        if ( this.filters.price_min == 'R$ 0,00' ) {
            ignore_fields.push('price_min')
        }

        if ( this.filters.price_max == 'R$ 0,00' ) {
            ignore_fields.push('price_max')
        }

        Object.keys(this.filters).forEach((field) => {
            let value = this.filters[field]
            if (value && ignore_fields.indexOf(field) == -1) {
                query[field]=value;
            }
        })

        Vue.set(this, '$advanced_filters', {})
        Vue.set(this, '$advanced_filters', this.filters)

        this.$emit("filterApplied", true)        
        this.$router.push({
                name: "principal-property-search",
                query: query,
                params: route_params
        });

    },

    onTheFlyForm(url, data) {

        var new_form = jQuery('<form>', {
            'action': url,
            'method': 'POST',
            'target': '_top'
        })

        var fields_list = ['bairro', 'tipo', 'quartos', 'vagas'] 

        Object.keys(data.filters).forEach((field)=>{
            let value  = data.filters[field]


            let input_name = `filters[${field}]${ (fields_list.indexOf(field) != -1 ? '[]' : '') }`

            if ( typeof value == "object" ) {

                value.forEach((value_item)=>{
                    let input = jQuery('<input>', {
                        'name': input_name,
                        'value': value_item,
                        'type': 'hidden'
                    })

                    new_form.append(input)
                })

            } else {
                let input = jQuery('<input>', {
                    'name': input_name,
                    'value': value,
                    'type': 'hidden'
                })

                new_form.append(input)
            }
            
            
        })

        new_form.appendTo('body').submit()
    },

    prepare_send_url() {

        var business = "alugar"
        var city = ""
        var district = ""
        var property_type = ""

        if (this.filters.business == "Vendas" || this.filters.business_subfilter == "Lançamentos") {
            business = "venda"
        }

        if (this.filters.business_subfilter == "Temporário") {
            business = "temporada"
        }

        if (this.filters.business_subfilter == "Triple A") {
            business = "triplea/comprar"
        }

        if ( this.filters.city != "" && this.filters.city != null) {
            city = "/" +  this.slugify(this.filters.city)
        }

        if ( this.filters.district && this.filters.district.length > 0 ) {
            district = this.slugify(this.filters.district[0])
        }

        if ( this.filters.property_type && this.filters.property_type.length > 0 ) {
            property_type = "/" + this.slugify(this.filters.property_type[0])
        }

        var url = "/" + business + property_type + city
        if (district != "") {
            url += "/" + this.slugify(district);
        }

        if ( this.filters.address != "" && this.filters.address != null) {
            url += "/" +  this.slugify(this.filters.address)
        }

        return this.old_site_url + url;


    },

    clearFilter(business_change = false) {

        if (!business_change) {
            this.filters.business= "Vendas"
            this.filters.business_subfilter= "Residencial"
        }
        
        this.filters.reference= null
        this.filters.city= null
        this.filters.district= []
        this.filters.property_type= []
        this.filters.property_type_combo= []
        this.filters.bedrooms= []
        this.filters.garage= []
        this.filters.price_max= null
        this.filters.price_min= null
        this.filters.address= null

        if (typeof this.$refs['vselectsearchby'] != "undefined") {
            this.$refs['vselectsearchby'].$children[0].clearSelection()
        }

        if (typeof this.$refs['vselectpropertytype'] != "undefined") {
            //this.$refs['vselectpropertytype'].$children[0].clearSelection()
        }

        if (typeof this.$refs['vselectcity'] != "undefined") {
            this.$refs['vselectcity'].$children[0].clearSelection()
        }

        if (typeof this.$refs['vselectdistrict'] != "undefined") {
            //this.$refs['vselectdistrict'].$children[0].clearSelection()
        }

    },

    loadExistingFilters(reload_filters = false) {

        let advanced_filters = this.clearFilterOnOpen ? {} : this.$advanced_filters
        let use_filters = reload_filters ? JSON.parse(JSON.stringify(this.filters)) : advanced_filters

        if (!use_filters) {
            return false
        }

        console.log("filters to apply: ", use_filters)

        this.$refs['vselectcity'].$children[0].updateValue('')
        setTimeout(() => {
            Object.keys(use_filters).forEach((key, i)=>{
            
                //this.filters[key] = use_filters[key]
                Vue.set(this.filters, key, use_filters[key])

                if ( key == "city" && use_filters[key]) {
                    this.$refs['vselectcity'].$children[0].updateValue(use_filters[key])
                }
            })

            if ( use_filters['property_type_combo'] && use_filters['property_type_combo'].length > 0 ) {
                
                use_filters['property_type_combo'].forEach((type_combo) => {

                    let types_in_combo = type_combo.split('|')
                    types_in_combo.forEach((type)=>{
                        type = type.trim()
                        if ( this.filters['property_type'].indexOf(type) == -1 &&  type != "Condominio" ) {
                            this.filters['property_type'].push(type)
                        }
                    })

                })

            }

        }, 500);

    },

    set_advanced_filters(use) {
        this.use_advanced_filters = use
        this.show_other_types = true
    }

  },

  
  mounted() {
    this.filters.business = "Vendas"

    PropertiesService.getFilters(this.data_index)
    .then(
        response => {
            //this.filters_options.cities = response.data.cities
            this.filters_options.types = response.data.types
            this.filters_options.references = response.data.referencias
            this.filters_options.adresses = response.data.enderecos

            let cities = []
            let districts = []
            let address = []

            //adjusted to place curitiba on first position
            let curitiba_obj = response.data.cities.filter(city_obj => city_obj.city == 'Curitiba')
            let other_cities = response.data.cities.filter(city_obj => city_obj.city != 'Curitiba')
            this.filters_options.cities = curitiba_obj.concat(other_cities)

            this.filters_options.cities.forEach((city)=>{
                cities.push(city.city)
                city.districts.forEach((district)=>{
                    districts.push(`${district}, ${city.city}`)
                })
            })

            response.data.enderecos.forEach((addrs)=>{
                let _address_without_number = `${addrs.address}, ${addrs.district}, ${addrs.city}`
                let _address_with_number = `${addrs.address}, ${addrs.number}, ${addrs.district}, ${addrs.city}`

                if ( address.indexOf(_address_without_number) == -1 ) {
                    address.push(_address_without_number)
                }

                if ( address.indexOf(_address_with_number) == -1 ) {
                    address.push(_address_with_number)
                }
            })


            this.filters_options.open_search = cities.concat(districts, address, this.filters_options.references)

            //se for espanhol/paragua não utilizar districts/bairros
            if ( this.lang=='es' ) {
                this.filters_options.open_search = cities.concat(address, this.filters_options.references)
            }
            

            this.loadExistingFilters()
        }
    )


    document.querySelector(".home-search-component").addEventListener("click",(e)=>{
        let el_is_property_type = e.target.closest(".property-types-wrapper")
        let el_is_trigger = e.target.closest(".trigger-property-types")
        if (!el_is_property_type && !el_is_trigger) {
            this.checkPropertyTypeHidden()
        }
    })

  },

  watch:{
    openAdvanced: function(openAdvanced) {
      //this.search(filters)
      this.use_advanced_filters = openAdvanced
    },
  }

};
</script>