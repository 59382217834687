<template>
    <div class="box-produtos-component">
        <div class="row main-row">

          <div class="col-md-6 products_buttons_area">

            <div class="products_buttons_area_wrapper">
                <template v-for="product,pkey in _products">
                    <div 
                        v-bind:key="`pkey_${pkey}`" 
                        :class="`product_button ${product.slug} ${(current_product == product.slug ? 'active' : '')}`"
                        
                        v-on:click="current_product=product.slug"
                    >
                    <!--button content here-->
                    </div>
                </template>
            </div>

          </div>

          <div class="col-md-6 product_content_wrapper">
            <div 
                v-if="cur_product" 
                class="product_content"
                :style="`${'background-color:' + cur_product.color}`"
            >
                <h2>{{__(cur_product.title)}}</h2>
                <div class="description" v-html="__(cur_product.description)"></div>

                <div class="buttons">
                    <a 
                        target="_blank"
                        v-for="button,key in cur_product.buttons" 
                        v-bind:key="`bt_${cur_product.slug}_${key}`"
                        :href="button.link"
                        :style="`${typeof button.style != 'undefined' ? button.style : ''}`"
                    >
                        {{__(button.label)}}
                    </a>
                </div>
            </div>
          </div>

        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import "@/assets/scss/customB/principal/components/_box_produtos_component.scss"
import { Carousel, Slide } from "vue-carousel";


export default {
  components: {
    Carousel,
    Slide
  },
  computed: {
    cur_product: function() {
        let product = this.products.filter(prd => prd.slug == this.current_product)
        return product.length > 0  ? product[0] : null
    },
    _products: function() {

        if ( this.lang == 'es' ) {
            let es_products = ['seja-um-franqueado', 'apolar-anuncie']
            return this.products.filter(prd => es_products.indexOf(prd.slug) != -1 )
        }
        return this.products
    }
  },
  data() {
    return {
        current_product: "seja-um-franqueado",
        products: [
            {
                "title": "Seja um Franqueado",
                "description": `Pensou em Empreender?\
    <br/>A Apolar Imóveis é uma grande oportunidade para você fazer do seu investimento uma marca de sucesso!\
    Invista em franquias imobiliárias, um modelo de negócio consolidado com uma das líderes do setor imobiliário do sul do país.\
    <br/><br/>Conheça nossos Benefícios!`,
                "buttons": [
                    {"label":"Seja um franqueado", "link":"https://franqueado.apolar.com.br", "style": " color: #fff; font-size: 15px;"}
                ],
                "slug": "seja-um-franqueado",
                "color": "rgba(251, 188, 5, 0.5)"
            },

            {
                "title": "Lançamentos Apolar",
                "description": `Seu novo lar pode estar aqui!\
    <br/><br/>Empreendimentos imobiliários novos que podem ser comprados na planta ou já prontos para morar.\
    <br/><br/>Apolar Lançamentos, projetando o futuro com você!`,
                "buttons": [
                    {"label":"Veja os lançamentos apolar", "link":"https://www.apolar.com.br/lancamentos", "style": " color: #FFFFFF; font-size: 15px;"}
                ],
                "slug": "lancamento-apolar",
                "color": "rgba(66, 102, 143, 0.5)"
            },

            {
                "title": "Apolar Short Stay",
                "description": `Com a Short Stay você encontra imóveis com vantagens e flexibilidade na moradia de curta estadia para aluguel temporário.<br/><br/>\
    Nosso processo de locação é ágil e rápido, com contratos flexíveis e imóveis 100% equipados.<br/><br/>\
    Aqui na Short Stay temos o imóvel pronto para você morar!`,
                "buttons": [
                    {"label":"Buscar imóveis short stay", "link":"https://shortstay.apolar.com.br/", "style": " color: #FFFFFF; font-size: 15px;"}
                ],
                "slug": "apolar-short-stay",
                "color": "rgba(142, 139, 187, 0.5)"
            },

            {
                "title": "Invista",
                "description": `Invista em imóveis e veja seus rendimentos aumentarem.<br/><br/>\
    Não fique com dinheiro parado no banco ou em investimentos que não geram retorno.<br/><br/>\
    Invista  Agora! É totalmente seguro, o imóvel é valorizado gerando lucro para você  investidor.`,
                "buttons": [
                    {"label":"Ver todos os imóveis", "link":"https://www.apolar.com.br/invista", "style": " color: #fff; font-size: 15px;"}
                ],
                "slug": "invista",
                "color": "rgba(216, 155, 0, 0.5)"
            },

            {
                "title": "Built for business",
                "description": `Construído para servir!<br/><br/>\
    É um modelo focado no ramo comercial, onde o imóvel é construído ou reformado especificamente para as necessidades da sua empresa.<br/><br/>\
    Pensado para você, personalizado para a sua empresa.`,
                "buttons": [
                    {"label":"Ver todos os imóveis", "link":"https://www.apolar.com.br/b4b", "style": " color: #ffffff; font-size: 15px;"}
                ],
                "slug": "built-for-business",
                "color": "rgba(132, 117, 93, 0.5)"
            },

            {
                "title": "Feirão Apolar",
                "description": `DESCONTOS para o seu lar!<br/><br/>\
    No feirão Apolar você encontra imóveis com valores imperdíveis.<br/><br/>\
    Não espere mais! compre agora o seu imóvel dos sonhos com descontos Reais!`,
                "buttons": [
                    {"label":"Ver imóveis em oferta", "link":"https://www.apolar.com.br/feirao-apolar", "style": " color: #ffffff; font-size: 15px;"}
                ],
                "slug": "feirao-apolar",
                "color": "rgba(255, 142, 78, 0.5)"
            },

            {
                "title": "Real estate - Triple A",
                "description": `A Apolar Triplo A é referência em imóveis de alto padrão, oferecendo os melhores negócios com uma assessoria personalizada\
    pelos nossos profissionais na área. Com a Real Estate os seus investimentos aumentam com segurança.`,
                "buttons": [
                    {"label":"Buscar por modalidade", "link":"https://www.apolar.com.br/triplea", "style": " color: #fff; font-size: 15px;"}
                ],
                "slug": "real-estate",
                "color": "rgba(191, 161, 84, 0.5)"
            },

            {
                "title": "Temporada",
                "description": `Com foco em imóveis no litoral, esse tipo de estadia foge da hospedagem convencional, oferecendo diversas vantagens.<br/><br/>\
    Aumente sua sensação de liberdade e conforto, alugue agora um imóvel e desfrute!`,
                "buttons": [
                    {"label":"Buscar por modalidade", "link":"https://www.apolar.com.br/temporada", "style": " color: #ffffff; font-size:15px;"}
                ],
                "slug": "temporada",
                "color": "rgba(0, 155, 124, 0.5)"
            },

            {
                "title": "Consórcio",
                "description": `Invista, realize e conquiste!<br/><br/>\
    Com a Apolar Consórcios, você escolhe uma parcela que cabe no seu bolso.<br/><br/>\
    É super fácil, escolha o valor do crédito e defina o tipo de carta de seu interesse.`,
                "buttons": [
                    {"label":"Faça uma simulação", "link":"https://consorcioapolar.com.br/", "style": " color: #FFFFFF; font-size: 15px;"}
                ],
                "slug": "consorcio",
                "color": "rgba(78, 94, 114, 0.5)"
            },

            {
                "title": "Apolar procura",
                "description": `Ainda não encontrou o imóvel que deseja?<br/><br/>\
    Você pode fazer um anúncio e divulgar exatamente as especificações do imóvel que está procurando.<br/><br/>\
    E conectamos você, proprietário, com os interessados que buscam imóveis como o seu. Um match perfeito!`,
                "buttons": [
                    {"label":"Descubra o imóvel perfeito", "link":"https://procura.apolar.com.br", "style": " color: #ffffff; font-size: 15px;"},
                ],
                "slug": "apolar-procura",
                "color": "rgba(212, 94, 94, 0.5)"
            },

            {
                "title": "Apolar indique",
                "description": `Quer ganhar um dinheiro extra e não sabe como ?<br/>\
    Indique agora  imóveis na Ops  Ganhei!<br/><br/>\
    Você indica um imóvel na OPS! Ganhei e ganha R$150,00 por imóvel que for publicado. Simples assim!`,
                "buttons": [
                    {"label":"Indique agora", "link":"https://app.opsganhei.com.br/indicacao/auth", "style": " color: #FFFFFF; font-size: 15px;"}
                ],
                "slug": "apolar-indique",
                "color": "rgba(97, 199, 174, 0.5)"
            },

            {
                "title": "Apolar anuncie",
                "description": `Anuncie seu imóvel sem complicações!<br/><br/>\
    Com a Apolar, anunciar pode ser rápido e fácil. Conheça agora todas as vantagens.`,
                "buttons": [
                    {"label":"Anuncie agora", "link":"https://anuncie.apolar.com.br/", "style": "color: #fff;  font-size: 15px; margin-top: 60px; display:block;"}
                ],
                "slug": "apolar-anuncie",
                "color": "rgba(252, 209, 57, 0.5)"
            }
        ]
    };
  },
  methods: {
    changeBgColor: function(e, product, action) {

        if ( product.slug == this.current_product ) {
            return false
        }

        let el = e.target 
        el.classList.toggle("hover");
        if (action == "on") {
           el.style.backgroundColor = product.color;
        } else {
            el.style.backgroundColor = null;
        }
    }
  },
  mounted() {
  }
};
</script>
<style>
</style>
