<template>
    <div class="prepared-search-component" :id="getElementId" :class="getHeaderClass">
        <div v-if="show_component" class="row">
            <div class="col-md-4 col-sm-12 col-xs-12">
                
                <template v-for="search, idx in preapred_searchs[lang]">

                    <button v-bind:key="`prepared_search_${idx}`" class="prepared-search-type"
                        :class="checkIsActive(search.label) + ' ' + search.class" @click="current_search = search.label">
                        <span class="label">{{ __(search.label) }}</span>
                    </button>

                    <div v-if="isMobile && current_search == search.label" class="prepared-search-view"
                        v-bind:key="`prepared_search_view_${idx}`">
                        <h2>{{ __(prepare_search.title) }}</h2>
                        <ul>
                            <li v-for="search, searchidx in prepare_search.searchs"
                                v-bind:key="`prepared-serach-item-${searchidx}`">
                                <a target="_blank" :href="search.link">{{ search.label }}</a>
                            </li>
                        </ul>
                    </div>

                </template>

            </div>

            <div v-if="!isMobile" class="col-md-8">
                <div class="prepared-search-view">
                    <h2>{{ __(prepare_search.title) }}</h2>
                    <ul>
                        <li v-for="search, searchidx in prepare_search.searchs"
                            v-bind:key="`prepared-serach-item-${searchidx}`">
                            <a target="_blank" :href="search.link">{{ search.label }}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        {{/* TODO: Complext trans format... __("Alugar Casa em Curitiba", "Alugar %s em %s")*/ }}

    </div>
</template>
<script>
import Vue from 'vue'
import "@/assets/scss/customB/principal/components/_prepared-searchs.scss"


export default {
    components: {

    },
    computed: {
        getHeaderClass() {
            return `prepared_searchs_${this.$route.name}`
        },
        prepare_search() {
            let filter_prepared_search = this.preapred_searchs[this.lang].filter(search => search.label == this.current_search)
            if (filter_prepared_search.length > 0) {
                return filter_prepared_search[0]
            }
            return this.preapred_searchs[this.lang][0]
        },
        pattern_trans() {
            let filter_prepared_search = this.preapred_searchs[this.lang].filter(search => search.label == this.current_search)
            let search_type = filter_prepared_search[0]['class']

            switch (search_type) {
                case "alugar-imoveis":
                    return "Alugar em %s"
                    break;

                case "comprar-imoveis":
                    return "Comprar em %s"
                    break;

                case "bairros-curitiba":
                    return "Imóveis no %s"
                    break;

                default:
                    return "Alugar em %s"
                    break;
            }
        },

        getElementId() {
            if (!this.element_id) {
                this.element_id = `prepared_search_${Math.random().toString(36).substr(2, 9)}`
            }
            return this.element_id
        }
    },
    data() {
        return {
            current_search: "",
            preapred_searchs: {
                'ptBR': [
                    {
                        "label": "Alugar Imóveis",
                        "class": "alugar-imoveis",
                        "title": "Os melhores imóveis para locação",
                        "searchs": [
                            {
                                "link": "/alugar/curitiba",
                                "label": "Alugar em Curitiba"
                            },
                            {
                                "link": "/alugar/sao-jose-dos-pinhais",
                                "label": "Alugar em São José dos Pinhais/PR"
                            },
                            {
                                "link": "/alugar/pinhais",
                                "label": "Alugar em Pinhais/PR"
                            },
                            {
                                "link": "/alugar/colombo",
                                "label": "Alugar em Colombo/PR"
                            },
                            {
                                "link": "/alugar/pato-branco",
                                "label": "Alugar em Pato Branco/PR"
                            },
                            {
                                "link": "/alugar/matinhos",
                                "label": "Alugar em Matinhos/PR"
                            },
                            {
                                "link": "/alugar/guaratuba",
                                "label": "Alugar em Guaratuba/PR"
                            },
                            {
                                "link": "/alugar/balneario-camboriu",
                                "label": "Alugar em Balneario Camboriú/SC"
                            },
                            {
                                "link": "/alugar/itapoa",
                                "label": "Alugar em Itapoá/SC"
                            },
                            {
                                "link": "/alugar/quatro-barras",
                                "label": "Alugar em Quatro Barras/PR"
                            },
                            {
                                "link": "/alugar/campo-largo",
                                "label": "Alugar em Campo Largo/PR"
                            },
                            {
                                "link": "/alugar/fazenda-rio-grande",
                                "label": "Alugar em Fazenda Rio Grande/PR"
                            },
                            {
                                "link": "/alugar/joinville",
                                "label": "Alugar em Joinville/SC"
                            },
                            {
                                "link": "/alugar/balneario-picarras",
                                "label": "Alugar em Piçarras/SC"
                            }
                        ]
                    },
                    {
                        "label": "Comprar Imóveis",
                        "class": "comprar-imoveis",
                        "title": "Os melhores imóveis para comprar",
                        "searchs": [
                            {
                                "link": "/venda/curitiba",
                                "label": "Comprar em Curitiba"
                            },
                            {
                                "link": "/venda/sao-jose-dos-pinhais",
                                "label": "Comprar em São José dos Pinhais/PR"
                            },
                            {
                                "link": "/venda/pinhais",
                                "label": "Comprar em Pinhais/PR"
                            },
                            {
                                "link": "/venda/colombo",
                                "label": "Comprar em Colombo/PR"
                            },
                            {
                                "link": "/venda/pato-branco",
                                "label": "Comprar em Pato Branco/PR"
                            },
                            {
                                "link": "/venda/matinhos",
                                "label": "Comprar em Matinhos/PR"
                            },
                            {
                                "link": "/venda/guaratuba",
                                "label": "Comprar em Guaratuba/PR"
                            },
                            {
                                "link": "/venda/balneario-camboriu",
                                "label": "Comprar em Balneario Camboriú/SC"
                            },
                            {
                                "link": "/venda/itapoa",
                                "label": "Comprar em Itapoá/SC"
                            },
                            {
                                "link": "/venda/quatro-barras",
                                "label": "Comprar em Quatro Barras/PR"
                            },
                            {
                                "link": "/venda/campo-largo",
                                "label": "Comprar em Campo Largo/PR"
                            },
                            {
                                "link": "/venda/fazenda-rio-grande",
                                "label": "Comprar em Fazenda Rio Grande/PR"
                            },
                            {
                                "link": "/venda/joinville",
                                "label": "Comprar em Joinville/SC"
                            },
                            {
                                "link": "/venda/balneario-picarras",
                                "label": "Comprar em Piçarras/SC"
                            }
                        ]
                    },
                    {
                        "label": "Bairros em Curitiba",
                        "class": "bairros-curitiba",
                        "title": "Os melhores bairros de Curitiba",
                        "searchs": [
                            {
                                "link": "/alugar/curitiba/batel",
                                "label": "Imóveis no Batel"
                            },
                            {
                                "link": "/alugar/curitiba/boa-vista",
                                "label": "Imóveis no Boa Vista"
                            },
                            {
                                "link": "/alugar/curitiba/agua-verde",
                                "label": "Imóveis no Água Verde"
                            },
                            {
                                "link": "/alugar/curitiba/tingui",
                                "label": "Imóveis no Tingui"
                            },
                            {
                                "link": "/alugar/curitiba/cristo-rei",
                                "label": "Imóveis no Cristo Rei"
                            },
                            {
                                "link": "/alugar/curitiba/sao-francisco",
                                "label": "Imóveis no São Francisco"
                            },
                            {
                                "link": "/alugar/curitiba/centro",
                                "label": "Imóveis no Centro"
                            },
                            {
                                "link": "/alugar/curitiba/santa-candida",
                                "label": "Imóveis no Santa Cândida"
                            },
                            {
                                "link": "/alugar/curitiba/ahu",
                                "label": "Imóveis no Ahú"
                            },
                            {
                                "link": "/alugar/curitiba/merces",
                                "label": "Imóveis no Mercês"
                            },
                            {
                                "link": "/alugar/curitiba/juveve",
                                "label": "Imóveis no Juvevê"
                            },
                            {
                                "link": "/alugar/curitiba/portao",
                                "label": "Imóveis no Portão"
                            },
                            {
                                "link": "/alugar/curitiba/bigorrilho",
                                "label": "Imóveis no Champagnat"
                            },
                            {
                                "link": "/alugar/curitiba/cajuru",
                                "label": "Imóveis no Cajuru"
                            }
                        ]
                    }
                ],
                'es': [
                // {
                //         "label": "Alugar Imóveis",
                //         "class": "alugar-imoveis",
                //         "title": "Os melhores imóveis para locação",
                //         "searchs": [
                //                         {
                //                             "link": "/alugar/asuncion",
                //                             "label": "Alquilar en Asunción"
                //                         },
                //                         {
                //                             "link": "/alugar/ciudad-del-este",
                //                             "label": "Alquilar en Ciudad del Este"
                //                         },
                //                         {
                //                             "link": "/alugar/san-lorenzo",
                //                             "label": "Alquilar en San Lorenzo"
                //                         },
                //                         {
                //                             "link": "/alugar/luque",
                //                             "label": "Alquilar en Luque"
                //                         },
                //                         {
                //                             "link": "/alugar/encarnacion",
                //                             "label": "Alquilar en Encarnación"
                //                         },
                //                         {
                //                             "link": "/alugar/lambare",
                //                             "label": "Alquilar en Lambaré"
                //                         }
                //                     ]
                //     },
                    {
                        "label": "Comprar Imóveis",
                        "class": "comprar-imoveis",
                        "title": "Os melhores imóveis para comprar",
                        "searchs": [
                                        {
                                            "link": "/venda/asuncion",
                                            "label": "Comprar en Asunción"
                                        },
                                        {
                                            "link": "/venda/ciudad-del-este",
                                            "label": "Comprar en Ciudad del Este"
                                        },
                                        {
                                            "link": "/venda/san-lorenzo",
                                            "label": "Comprar en San Lorenzo"
                                        },
                                        {
                                            "link": "/venda/luque",
                                            "label": "Comprar en Luque"
                                        },
                                        {
                                            "link": "/venda/encarnacion",
                                            "label": "Comprar en Encarnación"
                                        },
                                        {
                                            "link": "/venda/lambare",
                                            "label": "Comprar en Lambaré"
                                        }
                                    ]
                    }
                ]
            },
            element_id: null,
            show_component: false
        };
    },
    methods: {
        changeSearch(search_name) {
            this.current_search = search_name
        },
        checkIsActive(search_name) {
            return search_name == this.current_search ? 'active' : ''
        },
    },
    mounted() {

        // Create a new Intersection Observer
        const observer = new IntersectionObserver(entries => {
            if (this.show_component) {
                return false
            }
            entries.forEach(entry => {
                // Check if the target element is intersecting with the root
                if (entry.isIntersecting) {
                    this.changeSearch('Alugar Imóveis')
                    this.show_component = true
                    //console.log('Element is visible');
                } else {
                    //console.log('Element is not visible');
                }
            });
        });

        // Select the target element
        const targetElement = document.getElementById(this.getElementId);

        // Start observing the target element
        observer.observe(targetElement);

    }
};
</script>
<style></style>
