<template>
    <div class="advertise-search-box-component">
        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12 p-0">
                <h3>{{__("Quer anunciar seu imóvel?")}}</h3>

                <carousel class="view-box" :perPageCustom="carousel_responsive">
                    <slide v-for="box,bkey  in _boxes" v-bind:key="`box_${bkey}`">
                        <div class="box">
                            <div :class="`box-icon ${box.icon}`"></div>
                            <div class="box-title">{{__(box.title)}}</div>
                            <div class="box-description">{{__(box.description)}}</div>
                            <a target="_blank" class="box-url" :href="box.link.url">{{__(box.link.label)}}</a>
                        </div>
                    </slide>
                </carousel>

                <a target="_blank" class="box-main-button" :href="lang == 'es' ? '/fale-conosco' : 'https://www.apolar.com.br/anuncie'  ">{{__("Anunciar agora")}}</a>

            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import "@/assets/scss/customB/principal/components/_box_advertise_search.scss"
import { Carousel, Slide } from "vue-carousel";


export default {
  components: {
    Carousel,
    Slide
  },
  computed: {
      prepare_search() {
      },

      _boxes() {
        if (this.lang == 'es') {
            return this.boxes.filter(bx => bx.icon != 'icon-chart')
        }
        return this.boxes
      },

      carousel_responsive() {
        if (this.lang == 'es') {
            return [[300, 1],[400, 1],[800, 2],[1000, 2]]
        }
        return [[300, 1],[400, 1],[800, 2],[1000, 3]]
      }
  },
  data() {

    return {
        
        boxes: []

    };
  },
  methods: {
  },
  mounted() {

    this.boxes = [
            {
                "icon": "icon-house",
                "title": "Anuncie seu imóvel no maior portal imobilário do Brasil",
                "description": "Muito mais que uma plataforma, nosso atendimento e assessoria são humanizados e com profissionais que atuam no mercado há mais de 50 anos.",
                "link": {
                    "label": "Saiba mais sobre a Apolar",
                    "url": "https://www.apolar.com.br/institucional/"
                }
            },
            {
                "icon": "icon-hand",
                "title": "Quer vender seu imóvel?",
                "description": "Aqui você conta com a melhor divulgação online e offline, com mais de 100 lojas trabalhando em rede à procura da melhor negociação para você.",
                "link": {
                    "label": "Veja as vantagens Apolar",
                    "url": this.lang == 'es' ? '/institucional' : "https://www.apolar.com.br/anuncie"
                }
            },
            {
                "icon": "icon-chart",
                "title": "Investimento seguro e rentável?",
                "description": "O mercado imobiliário é uma excelente opção. Compre e alugue seu imóvel em um único portal, muito mais segurança para você.",
                "link": {
                    "label": "Confira as dicas de investimento",
                    "url": "https://www.apolar.com.br/invista"
                }
            }
        ]

  }
};
</script>
<style>
</style>
